import { MaxWidthView } from '@flix/common/elements/layout'
import Error404 from '../Error404/Error404'

const NotFoundPage = ({ staticContext }) => {
  if (staticContext) {
    staticContext.status = 404
  }
  return (
    <MaxWidthView>
      <Error404 />
    </MaxWidthView>
  )
}

export default NotFoundPage
